@font-face {
  font-family: "myanmar3";
  src: local("myanmar3"),
  url("./assets//fonts/myanmar3.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "AvertaStd-Regular";
  src: url("./assets/fonts/AvertaStd-Regular.ttf");
}
body {
  margin: 0;
  /* font-family: 'Quicksand' !important; */
  font-family: 'AvertaStd-Regular' !important;
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background:#fff;
}
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#b128cd, #50f8ea);
  border-radius: 25px;
}

:root{
  --bs-gray : #F7F7F7;
  --top-agent-budget: #FBAF03;
  --top-agent-budget-tile: #00000085;
  /* --primary-color: linear-gradient(#b128cd, #50f8ea); */
  --primary-color: #b128cd;

}

bottom{
  border: none;
  background-color: transparent;

}