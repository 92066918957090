/* Navigation */

.navigation-header {
  z-index: 500;
  width: 100%;
  position: fixed;
  top: 0;
}

.navigation-header.fixedNav {
  height: 75px !important;
  background-color: white;
  box-shadow: 2px -1px 11px #cdcdcd;
  transition: all ease 0.95s;
}

.mobile-nav-toggle {
  display: none;
}

.navigation-container {
  display: flex;
  gap: 2em;
  background-color: hsl(0 0% 100% / 0.8);
  padding: 1.5em;
  margin: 0.8em;
  align-items: center;
  border-radius: 10px;
  height: 40px;
  margin-right: 0.6em;
  z-index: 1000;
}

.navigation-container.fixedNav {
  background-color: white;
}

.navigation-item {
  /* width: 2em;
    aspect-ratio: 1;
    border-right: 1px solid var(--primary-color); */
}

.navigation-item.active {
  color: var(--primary-color) !important;
  font-weight: 550;
}

.active-nav-divider {
  width: 2px;
  height: 1.2em;
  background-color: var(--primary-color) !important;
  display: inline-block;
  margin-inline-start: 5px;
  transform: translateY(5px);
}

.navigation-container > ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 2em 1em;
}

.navigation-container > ul > li {
  margin-inline-end: 2em;
}

@media (max-width: 60em) {
  .navigation-container {
    /* height: 100vh; */
    /* position: fixed; */
    inset: 0 0 0 30%;
    z-index: 50;
    background-color: hsl(0 0% 100% / 0.75);
    transition: all 0.2s ease-in-out;
    height: 290px;
    width: 350px;
  }

  .navigation-container.fixedNav {
    background-color: hsl(0 0% 100% / 0.75);
  }

  .navigation-container > ul {
    flex-direction: column;
    padding: min(20vh, 10em) 2em;
  }

  .navigation-container > ul > li {
    /* color: #0f53a5; */
  }

  .navigation-container.hide {
    display: none;
    transform: translateX(100%);
  }

  .mobile-nav-toggle {
    position: absolute;
    border: none;
    background-color: transparent;
    top: 2em;
    right: 2em;
    z-index: 1000;
    display: block;
  }
}

@supports (backdrop-filter: blur(0.5em)) {
  .navigation-container {
    backdrop-filter: blur(0.5em);
    background-color: hsl(0 0% 100% / 0.8);
  }
}

.fb-brand-logo {
  width: 140px;
  margin: 1.2em;
}

.fb-navbar {
  background-color: transparent;
  top: 0;
  width: 100%;
  z-index: 5;
  position: fixed !important;
}

.fb-navbar.fixedNav {
  height: 67px !important;
  background-color: white;
  box-shadow: 2px -1px 11px #cdcdcd;
}
.fb-navbar-link {
  margin: 5px 0px 5px 27px;
  text-align: center;
  /* border-radius: 15px; */
  /* background-color: #fff; */
  /* transition: 0.2s; */
  text-shadow: 2px 1px 2px #fff;
}

.fb-navbar-link .fb-link-selected {
  height: 40px;
  /* border-bottom: 2px solid linear-gradient(#EAD6EE, #A0F1EA); */
  border-bottom: 3px solid #ead6ee;
  color: #ead6ee !important;
  /* border-radius: 15px; */
  /* color: #fff !important; */
}

.fb-link-search-box {
  opacity: 1;
  transition: opacity 5s;
}

.nav-link {
  color: #000 !important;
  letter-spacing: 3px;
  font-weight: 600;
  justify-content: center;
  text-align: center;
}
.navbar-nav {
  /* margin: 7px 0px; */
  background-color: transparent;
  /* box-shadow: 0 3px 5px #cdcdcd; */
}
.navbar-light .navbar-toggler,
.navbar-light .navbar-toggler:active,
.navbar-light .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

/*search box*/
.filter-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.search-box-btn {
  position: relative;
}

.searchBox-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #869ffd;
  color: #576490;
}
.search-box-btn .searchBox-container .searchBox {
  width: 'fit-content';
  /* border-radius: 24px; */
  border-color: #a0f1ea;
  background-color: transparent;
  border: none !important;
  border-radius: 0px;
  font-weight: 500;
  position: relative;
  transition: width 2s;
}

.search-box-btn .searchBox:hover {
  width: 250px !important;
  border: none;
  /* border-bottom: 1px solid #869FFD; */
}
.search-box-btn .searchBoxLong {
  width: 250px !important;
}

.search-box-btn .searchBox:focus {
  box-shadow: none;
  /* border-radius: 24px; */
  /* border-color: #A0F1EA; */
  border: none;
  /* border-bottom: 1px solid #869FFD; */
}

.search-box-btn .icon {
  padding: 10px;
  position: absolute;
  min-width: 40px;
  color: #d3b6ee;
  right: 0px;
  top: 0px;
  cursor: pointer;
}

.img-container {
  position: relative;
  margin-bottom: 12px;
}

.logo-container {
  /* margin-bottom: 5px;
    width: auto;
    height: auto;*/
  display: flex;
  text-align: center;
  justify-content: center;
}

.img-logo {
  width: 150px;
  height: 100px;
}

.categoryTitle {
  position: absolute;
  top: calc(50% - 24px);
  left: 0;
  text-align: center;
  width: 100%;
  font-size: 18px;
}
.trendingDiv {
  display: flex;
  justify-content: space-between;
}

.vertical-dotted-line {
  width: 50px;
  border-bottom: 3px solid #ead6ee;
  margin: 0 auto;
}

/*  product category */

.product-slider {
  min-height: 400px;
  aspect-ratio: 14 / 6;
  object-fit: cover;
}
.productPost {
  padding: 20px;
}
.productCategory {
  margin: 30px;
  background: #ffffff;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
  border-radius: 54px;
}
.productCategory > ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0px;
  color: #889fc0;
}
.productCategory > ul li {
  padding: 8px 1px;
  font-size: 16px;
  cursor: pointer;
}
.productCategoryActive {
  color: #ad4bdb;
  font-weight: 500;
}
.productCategory > ul li:focus {
  background-color: blue;
}

.product-list-container {
  margin-top: 8px;
  background-color: #f5f7f9;
  border-radius: 5px;
}

.fallback-image-container {
  background-color: blur(4px);
  height: 100%;
}
.product-card-container {
  /* width: 250px; */
  width: 100%;
  height: 100%;
  box-shadow: 0px 1px 6px 2px #eaeaea;
  border-radius: 5px;
  background-color: white;
}

.product-card-container:hover {
  background-color: #fcfcfc;
}
.product-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #eaeaea;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.product-image-info-container {
  line-height: normal;
  padding: 10px 10px 10px 20px;
}
.fallback-image {
  border-radius: 5px;
  width: 100%;
  background-color: #eaeaea;
  /* height: 500px; */
  /* object-fit: contain; */
}
.productAmount {
  font-size: 16px;
  color: #0f53a5;
  font-weight: 600;
  margin: 5px 0;
}
.productTitle {
  margin: 0;
  height: 53px;
  font-size: 15px;
  color: #8c8c8c;
}
.productOrder {
  margin: 0;
  height: 30px;
  font-size: 15px;
  color: #8c8c8c;
}

.brand-category-btn {
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: #fafafa;
  border: none;
  text-align: left;
}

.product-brand-button {
  background-color: white;
  width: 180px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  border: 1px solid #869ffd !important;
  color: #576490 !important;
  font-size: 15px !important;
  font-weight: 550 !important;
}

.product-brand-button::after {
  /* border-bottom: 2px solid #869FFD !important; */
  border-top: 0.3em solid #869ffd !important;
}

/* Product detailed */

.product-detailed--price {
  font-size: 17px;
  font-weight: 650;
  color: #0f53a5;
}

.bread-crumb-container {
  min-height: 6.5vh;
  color: #0f53a5;
  /* padding-left: 7.5em; */
  background-color: #bddaf8;
}

/* agent */

/* .top-agent-card-container{
    width: 29%;
    margin: 1.8em 2%;
} */

.agent-budge-container {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--top-agent-budget);
  left: -18px;
  top: -30px;
}

.top-agent-name {
  font-size: 18px;
  width: 98%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 600;
  color: dodgerblue;
}

.top-agent-group-name {
  margin-bottom: 0;
}

.top-agent-info {
  font-size: 12px;
}

.agent-budge-inner-circle {
  width: 45px;
  height: 45px;
  background-color: white;
  border-radius: 50%;
  color: var(--top-agent-budget);
  font-size: 30px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  z-index: 2;
}

.agent-right-budge {
  clip-path: polygon(13% 28%, 47% 4%, 84% 65%, 58% 64%, 51% 87%);
  background-color: var(--top-agent-budget-tile);
  width: 30px;
  height: 30px;
  top: 14px;
  left: 13px;
}

.agent-left-budge {
  clip-path: polygon(45% 2%, 86% 19%, 49% 99%, 36% 74%, 7% 85%);
  background-color: var(--top-agent-budget-tile);
  width: 30px;
  height: 30px;
  top: 12px;
  left: -16px;
}

.top-agent-info-container {
  background-color: #f5f7fb;
  border-radius: 25px;
}

.top-agent-inner-info-box {
  /* background-color: white; */
  border-radius: 25px;
  padding: 1rem;
}

.top-agent-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.top-agent-inner-info-container {
  width: calc(100% - 60px);
}

.agent-card-container {
  border: none !important;
  background-color: transparent;
  /* box-shadow: 0px 0px 4px #cdcdcd;  */
  /* width: 23%;  */
  /* margin: 1em 1%; */
  border-radius: 5px !important;
}

.agent-card-info {
  font-size: 14px;
  color: #6d6d6d;
}

.agent-profile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.agent-card--profile-container {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 10px !important; */
}

.agent-card-body {
  padding: 1em 0 !important;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.group-color-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: 66px;
  left: 50px;
}

.group-type-btn {
  width: fit-content;
  height: 33px;
  border-radius: 20px;
  padding: 0 15px;
  border: 1px solid #f6e9f9;
  background-color: transparent;
  box-shadow: 1px 1px 3px #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}

.group-type-btn:hover,
.group-type-btn.active {
  border: 1px solid #ececfb;
  background-color: #ececfb;
  color: #353459;
}

.group-color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.agent-detailed--cover {
  background-image: url('../images/agent_detail_bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  height: 250px;
}

.agent-detailed--info-container {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  height: 8em;
  bottom: -4em;
  left: 10%;
  right: 10%;
  width: 80%;
  box-shadow: 0px -1px 13px #cdcdcd;
  backdrop-filter: blur(1em);
}

.agent-detailed-profile {
  width: 60px;
  height: 60px;
  border-radius: 5px;
}

.top-agent-crown {
  top: 45px;
  right: -10px;
  /* color: var(--top-agent-budget);   */
}

.agent-detailed-page--info {
  /* width: 350px; */
  box-shadow: 0px 1px 4px #cdcdcd;
  border-radius: 15px;
  height: fit-content;
}

.agent-detailed-title {
  font-weight: 550;
}

.agent-detailed-value {
  color: #0f53a5;
  font-weight: 500;
}

/* .agent-detailed-post-list{ */
/* width: calc(100% - 400px); */

/* } */

.agent-back-btn {
  top: 25px;
  left: 33px;
  width: 35px;
  height: 35px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* product */

.product-detailed-img {
  /* width: 450px; */
  /* height: 450px; */
  /* width: 80%; */
  max-width: 380px;
}
.product-detailed--left-side {
  /* width: 60%; */
  margin-bottom: 20px;
}
.product-detailed--right-side {
  width: 30%;
  min-width: 40%;
}
.product-specification-tab {
  width: fit-content;
  padding: 0 15px;
  height: 40px;
  border: none;
  background-color: transparent;
}

.product-specification-tab.active {
  border-bottom: 2px solid #0f53a5;
  color: #0f53a5;
}

.product-category-type {
  color: #0f53a5;
}

.post-card-header {
  background-color: transparent !important;
}

.post-profile-column {
  width: 55px !important;
}

.product-post-profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.product-post-img {
  /* width: 250px; */
  height: 250px;
  width: 100%;
}

.see-more-post-btn {
  color: #696969;
  border: none;
  width: fit-content;
  background-color: transparent;
}

.post-card-img-container {
  padding: 0 !important;
}

.post-card-img {
  min-height: 550px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.post-card-description {
  font-weight: 550;
  line-height: 25px;
}

.post-owner-name {
  font-weight: 600;
  font-size: 16px;
}

/* .post-status{

} */
/* post */

.tags-container {
  box-shadow: 2px 2px 5px #cdcdcd;
  height: fit-content;
  border-radius: 8px;
}

.tagDiv {
  display: flex;
  flex-direction: row;
}
.tagList {
  display: flex;
  flex-wrap: wrap;
  height: 260px;
  overflow: hidden;
  overflow-y: scroll;
}
.tagList > ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0px;
  color: #000;
  text-align: center;
}
.tagList > ul li {
  padding: 8px 1px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #e2c8ff;
  margin: 8px;
  border-radius: 1.5rem;
}
.tagList > ul li.tagListActive {
  background-color: #e2c8ff;
}

.post-tag-item {
  width: fit-content;
  white-space: nowrap;
  padding: 0 20px;
  height: 33px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e2c8ff;
  border-radius: 1.5rem;
  margin: 3px;
}
.tagNumber {
  /* background-color: #E2C8FF; */
  color: #e2c8ff;
  width: 35px;
  height: 35px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.post-tag-container {
  top: 15px;
  right: 25px;
}

.post-tag-info {
  width: fit-content;
  min-height: 30px;
  height: fit-content;
  border-radius: 25px;
  background-color: white;
  padding: 0 10px;
  box-shadow: 1px -1px 7px #cdcdcd;
  color: #74c0f0;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-tag-info-name {
  white-space: pre-wrap;
  word-break: break-all;
}

.post-detailed-page {
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  min-height: calc(100vh - 10em);
  height: auto;
}

.post-detailed-left-side {
  background-color: #f5f7fb;
  height: auto;
  /* min-height: calc(100vh - 10.1em); */
  position: relative;
}
/* Modal box in product detail */
.productDetailPage .detail-review-post-btn {
  display: flex;
  justify-content: space-evenly;
}
.productDetailPage .detail-review-post-btn button {
  width: 130px;
  color: #cca1f4;
  border-radius: 1.5rem;
  border-color: #d3b6ee;
  background-color: #fff;
}
.productDetailPage .detail-review-post-btn .active {
  width: 130px;
  color: #fff;
  border-radius: 1.5rem;
  border-color: #d3b6ee;
  background-color: #d3b6ee;
}

.post-detailed-img {
  /* height: 100%; */
  width: 99%;
}
/* Carousel item */
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #ae69f0 !important;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 4% 1%;
}
/* ProductReview */
.productReview .reviewDiv {
  margin: 20px;
  padding: 30px 40px;
  background-color: #bd85f242;
  border-radius: 20px;
}
/*  view more */
.viewMoreLink {
  color: #fff !important;
  background-color: #ae69f0;
  border: 2px solid #ae69f0;
  letter-spacing: 0px;
  font-size: 13px;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: -12px;
  border-radius: 10px;
  width: 115px;
  height: 2rem;
  /* left: 85%; */
  /* margin-left: 800px; */
  align-items: right;
  padding: 5px;
  float: right;
}
.viewMoreLink:hover {
  box-shadow: 2px 2px 30px #ae69f0;
  transform: translateY(-3px);
}

.viewmore-button {
  background-image: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%);
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 13px;
  border-radius: 10em;
  border: none;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 2em;
  width: 9.5em;
  padding-right: 3.3em;
  cursor: pointer;
  font-weight: 100;
  /* box-shadow: 0 0 1.6em rgba(183, 33, 255,0.3),0 0 1.6em hsla(191, 98%, 56%, 0.3); */
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.viewmore-button .icon {
  background: white;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.6em;
  width: 1.8em;
  border-radius: 10em;
  right: 0.3em;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.viewmore-button:hover .icon {
  width: calc(100% - 0.6em);
}

.viewmore-button .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: #b721ff;
}

.viewmore-button:hover .icon svg {
  transform: translateX(0.1em);
}

.viewmore-button:active .icon {
  transform: scale(0.9);
}

/* Not Found */
.notfound-404 {
  /* font-family: 'Fredoka One', cursive; */
  font-size: 40px;
  /* color: #ae69f0; */
  text-transform: uppercase;
  margin-top: 30px;
}
.notfound-home-button {
  background-image: linear-gradient(60deg, #21d4fd 0%, #b721ff 100%);
  color: white;
  font-family: inherit;
  padding: 0.35em;
  border-radius: 10em;
  border: none;
  align-items: center;
  text-align: center;
  width: 132px;
  height: 40px;
  font-size: 14px;
  margin-top: 30px;
  justify-content: center;
}

.notfound-home-button:hover {
  transform: translateY(-2px);
}
.notfound-home-button:active {
  box-shadow: inset 3px 3px 30px 0 rgba(0, 0, 0, 0.2),
    inset -3px -3px 30px 0 rgba(0, 0, 0, 0.2);
}

/* Pagination */
.paginationNum .pagination {
  margin-top: 25px;
  margin-bottom: -60px;
  /* background-color: #f0f0f0; */
  border-radius: 10px;
  height: 40px;
}
.paginationNum .pagination li {
  border-radius: 50%;
  width: 40px;
  height: 35px;
  margin-top: -20px;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  color: #000;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.paginationNum .pagination li:first-child,
.paginationNum .pagination li:last-child {
  color: #4d85d3;
}
.paginationNum .pagination li:first-child.disabled,
.paginationNum .pagination li:last-child.disabled {
  color: #d6d3d6;
}
.paginationNum .pagination li.disabled {
  color: #d6d3d6;
}
.paginationNum .pagination li.active {
  border-radius: 50%;
  width: 42px;
  height: 35px;
  font-size: 13px;
  /* text-decoration: underline; */
  color: #f7f3f9;
  /* font-weight: 500; */
  background-color: #8a359b !important;
}
.paginationNum h1 {
  color: #000;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
}
.paginationDiv .paginationArrow {
  padding: 8px;
  color: #cb0cf1;
  cursor: pointer;
}
.paginationDiv .paginationArrow.disabled {
  padding: 8px;
  color: #ded8d8;
}

.agent-image {
  width: 90px;
  height: 90px !important;
  border-radius: 50%;
  height: auto;
  border: 3px solid #a0f1ea;
  margin-left: 40px;
  margin-bottom: -25px;
  object-fit: cover;
}

.agent-detail-image {
  width: 90px;
  height: 90px !important;
  border-radius: 50%;
  border: 3px solid #a0f1ea;
  object-fit: cover;
  margin: 0 auto;
}

.agent-background-border {
  border-radius: 15px;
  border: 1px black #ead6ee;
  overflow: hidden;
}

.agent-background {
  background-color: #ead6ee;
  width: '100%';
  height: '200px';
  height: 200px;
  padding: 50px;
}

.agent-bg-color {
  background: rgba(160, 241, 234, 0.18);
}

.review-bg-color {
  background: rgba(234, 214, 238, 0.61);
}

.review-box {
  background: rgba(160, 241, 234, 0.5);
  padding: 30px;
  border-radius: 15px;
  margin-bottom: 50px;
  width: 400px;
}

.review-text {
  font-size: 14px;
}

.review-customer {
  font-size: 12px;
  margin: 0;
}

.post-card {
  border-radius: 15px !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
}

.post-image {
  width: 100%;
  height: 200px;
  padding-bottom: 20px;
  object-fit: cover;
}

.post-detail-image {
  width: 100%;
  height: 400px;
  padding-bottom: 20px;
  object-fit: cover;
}

.post-detail-multi-image {
  width: 100%;
  height: 500px;
  object-fit: cover;
  padding: 15px;
}

.card-name {
  /* color: #C88FFF; */
  color: #303030;
  font-weight: 550;
  font-size: 17px;
  margin: 0;
}

.card-date {
  /* color : #D3B6EE; */
  color: #696969;
  font-size: 14px;
  margin: 2px;
}

.card-product {
  font-size: 14px;
}

.profile-image {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  object-fit: cover;
  border: 3px solid #a0f1ea;
}

.post-detail-btn {
  background-color: transparent;
  border: none;
  color: #162bc7;
  float: right;
  margin-right: 15px;
  font-weight: 500;
}
/* about */
.aboutTitle {
  font-size: 22px;
  text-align: center;
  background-color: #67d1d1;
  padding: 5px;
}
.aboutBody {
  border: 1px solid #67d1d1;
  padding: 15px 20px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0 !important;
}
.about-bg-color {
  height: 300px;
  background: #a0f1ea;
}

.sub-title-text {
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
}

.post-description {
  font-family: 'myanmar3';
}

.tab-icon {
  width: 20px;
  height: 20px;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.col-centered {
  float: left;
}
.animate__animated.animate__fadeIn,
.animate__animated.animate__fadeDown {
  animation-duration: 3s;
}

/* footer section */

.fb-footer-container {
  background-color: rgba(0, 0, 0, 0.03) !important;
  color: #898c97;
}

.fb-footer-text {
  color: #898989;
}

.footer-separator {
  border-bottom: 2px solid #f1cfcf;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
}

.box {
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.footer-row {
  display: grid;
  grid-template-columns: repeat(4, minmax(195px, 1fr));
  grid-gap: 74px;
}

.footer-column {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 13px;
  word-spacing: normal;
}

.footerLink {
  color: #fff;
  margin-bottom: 20px;
  font-size: 18px;
  text-decoration: none;
  &:hover {
    color: green;
    transition: 200ms ease-in;
  }
}

.footer-social-box {
  margin-right: 30px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.footer-social-box.twitter {
  width: 30px;
  height: 30px;
}
.footer-img-container {
  /* background-color: #000; */
  padding: 0;
  width: 135px;
  height: 60px;
}

.footer-logo {
  display: flex;
  justify-content: space-around;
  margin-left: 15px;
  margin-right: 15px;
  align-items: center;
}

@media (max-width: 990px) {
  .logo-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(100px, auto);
  }

  .logo-container .img-logo {
    height: 100px;
    width: auto;
  }

  .fb-navbar-link {
    margin: 5px 0px;
    background-color: #f8f2f9;
  }

  /*  product category */
  .productCategory {
    margin: 10px;
    box-shadow: none;
    height: 220px;
    overflow-y: scroll;
    border-radius: 0px;
  }
  .productCategory > ul {
    flex-direction: column;
  }
  .productCategory > ul li {
    margin: 10px;
    padding: 8px 5px;
    box-shadow: 0px 0px 9px rgb(0 0 0 / 25%);
    border-left: 3px solid black;
  }

  .search-box-btn .searchBox,
  .search-box-btn .searchBox:hover,
  .search-box-btn .searchBoxLong {
    width: 100% !important;
    border: none;
    background-color: transparent;
  }

  .review-box {
    width: 300px;
  }
  /* Carousel item */
  .carousel-dark .carousel-indicators [data-bs-target] {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0.5%;
  }
  .productDetailPage .detail-review-post-btn button,
  .productDetailPage .detail-review-post-btn .active {
    width: 100px;
  }
  .productPost {
    padding: 10px;
  }
  .tagDiv {
    display: flex;
    /* flex-direction: column-reverse; */
  }

  .footer-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: auto;
    font-size: 11px;
  }

  .footer-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(100px, auto);
  }

  .footer-logo {
    display: flex;
    justify-content: space-around;
    margin-left: 95px;
    margin-right: 95px;
  }
}

@media (max-width: 767px) {
  .fb-navbar-link {
    margin: 5px 0px;
    background-color: #f8f2f9;
  }
  .fb-link-search-box {
    width: 100%;
  }
  /* .search-box-btn .searchBox ,
    .search-box-btn .searchBox:hover,
    .search-box-btn .searchBoxLong {
        width: 100% !important;
        border:none;
        background-color: transparent;
    } */
  .col-12 {
    margin-bottom: 15px;
  }
  .review-box {
    width: 100%;
  }
  /* Carousel item */
  .carousel-dark .carousel-indicators [data-bs-target] {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0.5%;
  }
  .productDetailPage .detail-review-post-btn button,
  .productDetailPage .detail-review-post-btn .active {
    width: 80px;
  }
  .productPost {
    padding: 0px;
  }
  .tagDiv {
    display: flex;
    flex-direction: column-reverse;
  }
}

a {
  color: inherit !important;
  text-decoration: none !important;
}

@media (max-width: 600px) {
  .logo-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
  }

  .logo-container .img-logo {
    height: 100px;
    width: auto;
  }

  .filter-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 15px;
  }

  .search-box-btn .searchBox-container .searchBox,
  .search-box-btn .searchBox-container .searchBox:hover,
  .search-box-btn .searchBox-container .searchBoxLong {
    width: 100% !important;
    border: none;
    background-color: transparent;
    margin-left: 0;
  }

  .footer-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: auto;
    font-size: 11px;
  }

  .footer-row {
    display: grid;
    grid-template-columns: repeat(1, minmax(195px, 1fr));
    grid-gap: 74px;
  }

  .footer-logo {
    display: flex;
    justify-content: space-around;
    margin-left: 95px;
    margin-right: 95px;
  }

  .top-agent-inner-info-container {
    width: calc(100% - 60px);
    padding: 15px;
  }

  .product-slider {
    min-height: 350px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
}

/* main body */

.fb-main-body {
  margin-top: 70px !important;
}

.breadcrumb-list {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 0 0 1em !important;
}

/* filter section */

.filter-container {
  gap: 15px;
}

/* loading */
.loading {
  /* margin-left: 47.5%;
    margin-top: 50%; */
  /* margin: auto; */
  /*background: #fff; */
  display: block;
  shape-rendering: auto;
  /* position: absolute; */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
}

.debounce-input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #08a8c5;
}

.debounce-input:active {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #08a8c5;
}

.tranding-product {
  aspect-ratio: 1/1;
  /* width: 100%; */
  height: 100%;
  object-fit: contain;
  padding: 5px 0;
}

.product-image {
  aspect-ratio: 1/1;
  width: 100%;
  object-fit: contain;
  height: 100%;
  padding: 5px 0;
}

.pdf_view_container {
  /* position: relative; */
  width: 100%;
}

.embed-responsive {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  /* height: calc(100vh - 300px); */
  max-height: 100px;
  /* background-color: pink; */
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border: none;
  background-color: pink;
  overflow-y: auto;
}

.download {
  border: 0;
  border-radius: 10px;
  color: #fff;
  background-color: #4f55c1;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  width: 40px;
  height: 40px;
  text-decoration: none;
  transition: 0.3s;
  padding: 0 25px;
  /* position: fixed; */
  /* z-index: 3000; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: outline 0.1s ease-out;
}

.download > button {
  background-color: transparent;
  border: none;
  color: white;
}

.download:hover,
download:active,
download:focus-visible {
  outline: 2px solid #4f55c1;
  outline-offset: 2px;
}
/* .download:before {
  font-family: 'ionicons';
  content: '\f2dd';
  font-size: 26px;
  color: #0ff;
  position: absolute;
  transform: translate(-50%, -50%);
  transition: .3s;
}

.download:hover:before {
  display: none;
}

.download:hover:after {
  font-family: Prompt;
  content: 'DOWNLOAD';
  color: #0ff;
  font-size: 18px;
  position: absolute;
  transform: translate(-50%, -50%);
  transition: .3s;
  animation: fadein .3s;
}

.download:hover {
  width: 160px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
} */

.app_download_banner {
  background-color: rgb(232 242 241 / 80%);
  padding-top: 3rem;
  margin-top: 7rem;
}

.app_download_banner_grid {
  display: grid;
  margin: 0 auto;
}

.app_download_banner_header {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.app_download_banner_text {
  color: rgb(75 85 99 / 80%);
}

.app_download_banner_image {
  width: 100%;
  height: 450px;
  object-fit: contain;
}
.app_download_link_btn {
  margin: 8px 0;
}

.app_download_link_btn p {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 5px;
  text-align: left;
}

.app_download_link_btn h3 {
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1;
  margin: 0;
}
